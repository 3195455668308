<content-locations>
  <div class="row fill-parent-height-64">
    <companies></companies>
    <locations></locations>
  </div>

  <script>
    import app from 'obs/app/_app.js';
    var tag = this;

    tag.on("mount", function()
    {
      tag.loadLocationsData();
    });

    tag.on("unmount", function()
    {

    });

    tag.loadLocationsData = () =>
    {
      app.loadLocationsData();
    }
  </script>
</content-locations>
