import riot from "riot"

export default class Categories
{
  constructor()
  {
    riot.observable(this);

    this.categories = [];

    this.signals = {
      success: {
        onAddCategory   : "categories_success_onAddCategory"
      , onEditCategory  : "categories_success_onEditCategory"       //trigger sends the edited category
      , onLoadCategories: "categories_success_onLoadCategories"
      , onRemoveCategory: "categories_success_onRemoveCategory"
      }
    , error: {
        onAddCategory   : "categories_error_onAddCategory"
      , onEditCategory  : "categories_error_onEditCategory"
      , onLoadCategories: "categories_error_onLoadCategories"
      , onRemoveCategory: "categories_error_onRemoveCategory"
      }
    , actions: {
        loading             : "categories_actions_loading"
      , loaded              : "categories_actions_loaded"
      , onLoadCategoriesData: "categories_actions_onLoadCategoriesData"
      }
    }

    var self = this;
  }

  loadCategories()
  {
    return new Promise((resolve, reject) => {
      this.trigger(this.signals.actions.loading);
      firebase.firestore().collection("categories")
      .get()
      .then((querySnapshot) => {
        this.categories = [];
        querySnapshot.forEach((doc) => {
          this.categories.push(doc.data());
          this.categories[this.categories.length - 1].id = doc.id;
          this.categories[this.categories.length - 1].itemsCount = 0;
          if(this.categories[this.categories.length - 1].columnNames == undefined)
            this.categories[this.categories.length - 1].columnNames = [];
        });

        this.categories.sort(function(a, b)
        {
          if(a.name < b.name) return -1;
          if(a.name > b.name) return 1;
          return 0;
        });

        this.trigger(this.signals.success.onLoadCategories);
        this.trigger(this.signals.actions.loaded);
        resolve();
      })
      .catch((error) =>
      {
        this.trigger(this.signals.error.onLoadCategories);
        this.trigger(this.signals.actions.loaded);
        console.log("Error getting documents: ", error);
        reject();
      });
    });
  }

  addCategory(categoryName)
  {
    this.trigger(this.signals.actions.loading);
    let category = {name: categoryName};
    firebase.firestore().collection("categories")
    .add(category)
    .then((doc) =>
    {
      this.trigger(this.signals.success.onAddCategory);
    })
    .catch((error) =>
    {
      this.trigger(this.signals.actions.loaded);
      this.trigger(this.signals.error.onAddCategory);
    });
  }

  editCategory(categoryID, newCategoryData)
  {
    this.trigger(this.signals.actions.loading);
    firebase.firestore().collection("categories").doc(categoryID).update(newCategoryData)
    .then((doc) =>
    {
      this.loadCategories();
      this.trigger(this.signals.actions.loaded);
      this.trigger(this.signals.success.onEditCategory, doc.data());
    })
    .catch((error) =>
    {
      this.trigger(this.signals.actions.loaded);
      this.trigger(this.signals.error.onEditCategory);
    });
  }

  removeCategory(categoryID)
  {
    return new Promise((resolve, reject) => {
      this.trigger(this.signals.actions.loading);
      for(var c in this.categories)
      {
        if(
             this.categories[c].id == categoryID
          && this.categories[c].itemsCount != 0
          )
        {
          this.trigger(this.signals.actions.loaded);
          this.trigger(this.signals.error.onRemoveCategory);
          reject();
          return;
        }
      }

      firebase.firestore().collection("categories").doc(categoryID)
      .delete()
      .then(() =>
      {
        this.trigger(this.signals.actions.loaded);
        this.trigger(this.signals.success.onRemoveCategory);
        resolve();
      })
      .catch(function(error)
      {
        this.trigger(this.signals.actions.loaded);
        console.error("Error removing document: ", error);
        reject();
      });
      resolve();
    });
  }
}
