<app-container>
  <div class="fill-parent-height">
  <navbar></navbar>
  <div id = "appContent" class = "fill-parent-height"></div>

  </div>
  <script>
    import app from 'obs/app/_app.js';
    import route from 'riot-route'

    var tag = this;

    tag.currentSubpage = undefined;

    tag.on("mount", function()
    {
      tag.createRoutes();
    });

    tag.on("unmount", function()
    {

    });

    var r = route.create();

    // riotjs router
    tag.createRoutes = () =>
    {
      route.stop();
      //Start listening the url changes and also exec routing on the current url.
      route.start()
      r("", tag.mountInventory);
      r("inventory", tag.mountInventory);
      r("locations", tag.mountLocations);
      route.exec();
    }

    tag.unmountCurrentSubpage = () =>
    {
      if(tag.currentSubpage != undefined)
      {
        tag.currentSubpage.unmount(true);
      }
    }

    tag.mountInventory = () =>
    {
      tag.unmountCurrentSubpage();
      tag.currentSubpage = riot.mount("#appContent", "content-inventory")[0];
      app.changeMode("Inventory");
      tag.update();
    }

    tag.mountLocations = () =>
    {
      tag.unmountCurrentSubpage();
      tag.currentSubpage = riot.mount("#appContent", "content-locations")[0];
      app.changeMode("Locations");
      tag.update();
    }
  </script>

</app-container>
