firebase.auth().onAuthStateChanged((user) => {
  if (!user)
  {
    // No user is signed in.
    console.log('not logged in');
    window.location.href = '/';
  }
  else
  {
    riot.mount('app-container');
  }
});

import riot from 'riot';
import 'materialize-css';
import M from 'materialize-css';
import 'materialize-css/dist/css/materialize.min.css';
import 'typeface-quicksand';

import 'riot-hot-reload';

import './tags/app-container/app-container.tag';
import './tags/app-container/navbar/navbar.tag';
import './tags/app-container/content-inventory/content-inventory.tag';
import './tags/app-container/content-inventory/categories/categories.tag';
import './tags/app-container/content-inventory/item-details/item-details.tag';
import './tags/app-container/content-inventory/item-details/item-approve-delete/item-approve-delete.tag';
import './tags/app-container/content-inventory/items/items.tag';
import './tags/app-container/content-inventory/items/item-attributes-modal/item-attributes-modal.tag';
import './tags/app-container/content-inventory/items/items-add-item-modal/items-add-item-modal.tag';
import './tags/app-container/content-inventory/items/items-edit-item-modal/items-edit-item-modal.tag';
import './tags/app-container/content-locations/content-locations.tag';
import './tags/app-container/content-locations/companies/companies.tag';
import './tags/app-container/content-locations/locations/locations.tag';
import './tags/app-container/content-locations/locations/location-approve-delete/location-approve-delete.tag';
