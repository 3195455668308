import riot from "riot"

export default class Companies
{
  constructor()
  {
    riot.observable(this);

    this.companies = [];

    this.signals = {
      success: {
        onAddCompany   : "companies_success_onAddCompany"
      , onEditCompany  : "companies_success_onEditCompany"       //trigger sends the edited company
      , onLoadCompanies: "companies_success_onLoadCompanies"
      , onRemoveCompany: "companies_success_onRemoveCompany"
      }
    , error: {
        onAddCompany   : "companies_error_onAddCompany"
      , onEditCompany  : "companies_error_onEditCompany"
      , onLoadCompanies: "companies_error_onLoadCompanies"
      , onRemoveCompany: "companies_error_onRemoveCompany"
      }
    , actions: {
        loading            : "companies_actions_loading"
      , loaded             : "companies_actions_loaded"
      , onLoadCompaniesData: "companies_actions_onLoadCompaniesData"
      }
    }

    var self = this;
  }

  loadCompanies()
  {
    return new Promise((resolve, reject) => {
      this.trigger(this.signals.actions.loading);
      firebase.firestore().collection("companies")
      .get()
      .then((querySnapshot) => {
        this.companies = [];
        querySnapshot.forEach((doc) => {
          this.companies.push(doc.data());
          this.companies[this.companies.length - 1].id = doc.id;
          this.companies[this.companies.length - 1].locationsCount = 0;
          if(this.companies[this.companies.length - 1].columnNames == undefined)
            this.companies[this.companies.length - 1].columnNames = [];
        });

        this.companies.sort(function(a, b)
        {
          if(a.name < b.name) return -1;
          if(a.name > b.name) return 1;
          return 0;
        });

        this.trigger(this.signals.success.onLoadCompanies);
        this.trigger(this.signals.actions.loaded);
        resolve();
      })
      .catch((error) =>
      {
        this.trigger(this.signals.error.onLoadCompanies);
        this.trigger(this.signals.actions.loaded);
        console.log("Error getting documents: ", error);
        reject();
      });
    });
  }

  addCompany(companyName)
  {
    return new Promise((resolve, reject) => {
      this.trigger(this.signals.actions.loading);
      let company = {name: companyName, columnNames: ["1 Company", "2 ID", "3 City", "4 ZIP code", "5 Street, house no", "6 E-mail", "7 Phone"]};
      firebase.firestore().collection("companies")
      .add(company)
      .then((doc) =>
      {
        this.trigger(this.signals.actions.loaded);
        resolve();
      })
      .catch((error) =>
      {
        this.trigger(this.signals.actions.loaded);
        this.trigger(this.signals.error.onAddCompany);
        reject();
      });
    });
  }

  editCompany(companyID, newCompanyData)
  {
    this.trigger(this.signals.actions.loading);
    firebase.firestore().collection("companies").doc(companyID).update(newCompanyData)
    .then((doc) =>
    {
      this.loadCompanies();
      this.trigger(this.signals.actions.loaded);
      this.trigger(this.signals.success.onEditCompany, doc.data());
    })
    .catch((error) =>
    {
      this.trigger(this.signals.actions.loaded);
      this.trigger(this.signals.error.onEditCompany);
    });
  }

  removeCompany(companyID)
  {
    return new Promise((resolve, reject) => {
      this.trigger(this.signals.actions.loading);
      for(var c in this.companies)
      {
        if(
             this.companies[c].id == companyID
          && this.companies[c].locationsCount != 0
          )
        {
          this.trigger(this.signals.actions.loaded);
          this.trigger(this.signals.error.onRemoveCompany);
          reject();
          return;
        }
      }

      firebase.firestore().collection("companies").doc(companyID)
      .delete()
      .then(() =>
      {
        this.trigger(this.signals.actions.loaded);
        this.trigger(this.signals.success.onRemoveCompany);
        resolve();
      })
      .catch(function(error)
      {
        this.trigger(this.signals.actions.loaded);
        this.trigger(this.signals.error.onRemoveCompany);
        console.error("Error removing document: ", error);
        reject();
      });
    });
  }
}
