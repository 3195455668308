<item-attributes-modal>
  <div id="itemAttributes" class="modal modal-fixed-footer">
    <div class="modal-content">
      <h6 class = "red-text center" show = {error_dotInAttribute}>Atributes cannot contain dots</h6>
      <ul>
        <li class = "attribute-element" show = {addingNewAttribute}>
          <input placeholder = "Column name" id="attributeName" type="text" class="validate">
        </li>
        <li each = {columnName, id in columnNames} class = "attribute-element">
          {columnName.name}
          <i class="material-icons right" onclick = {shiftDown} if = {id < (columnNames.length - 1)}>keyboard_arrow_down</i>
          <i class="material-icons right" onclick = {shiftUp} if = {id > 0}>keyboard_arrow_up</i>
        </li>
      </ul>
    </div>
    <div class="modal-footer">
      <a class="waves-effect waves-light btn-flat item-button" onclick = {addNewAttribute} show = {!addingNewAttribute}>Add new column</a>
      <a class="waves-effect waves-light btn-flat item-button" onclick = {cancelChanges}>Cancel</a>
      <a class="waves-effect waves-light btn-flat item-button" onclick = {saveChanges}>Save</a>
    </div>
  </div>

  <script>
  import app from 'obs/app/_app.js';
  var tag = this;

  tag.columnNames            = [];
  tag.checkedCategoriesCount = 0;
  tag.checkedCategory        = undefined;
  tag.addingNewAttribute     = false;

  tag.error_dotInAttribute = false;

  tag.on("mount", function()
  {
    app.categories.on("categories_actions_onLoadCategoriesData", tag.loadCategoriesData);
    document.getElementById("attributeName").addEventListener('keydown', tag.addAttribute);
  });

  tag.on("before-unmount", function()
  {
    document.getElementById("attributeName").removeEventListener('keydown', tag.addAttribute);
  });

  tag.on("unmount", function()
  {
    app.categories.off("categories_actions_onLoadCategoriesData", tag.loadCategoriesData);
  });

  tag.loadCategoriesData = () =>
  {
    tag.columnNames = [];

    tag.checkedCategoriesCount = 0;
    tag.checkedCategory = undefined;

    let columns = [];

    // check number of categories that the user has selected + generate column names
    for (
          var c = 0
        ; c < app.categories.categories.length
        ; ++c
        )
    {
      if(app.categories.categories[c].checked)
      {
        tag.checkedCategoriesCount++;
        tag.checkedCategory = app.categories.categories[c];
        for (
              var i = 0
            ; i < app.categories.categories[c].columnNames.length
            ; ++i
            )
        {
          columns.push({name: app.categories.categories[c].columnNames[i]});
        }
      }
    }

    columns.forEach(function(item)
    {
      if(tag.columnNames.indexOf(item) < 0)
      {
        tag.columnNames.push(item);
      }
    });

    tag.update();
  }

  tag.shiftUp = (e) =>
  {
    let item = e.item;

    for(var c in tag.columnNames)
    {
      if(tag.columnNames[c].name == item.columnName.name)
      {
        let tmp                = JSON.parse(JSON.stringify(tag.columnNames[c]));
        tag.columnNames[c]     = JSON.parse(JSON.stringify(tag.columnNames[parseInt(c) - 1]));
        tag.columnNames[parseInt(c) - 1] = JSON.parse(JSON.stringify(tmp));
        break;
      }
    }

    tag.update();
  }

  tag.shiftDown = (e) =>
  {
    let item = e.item;

    for(var c in tag.columnNames)
    {
      if(tag.columnNames[c].name == item.columnName.name)
      {
        let tmp                = JSON.parse(JSON.stringify(tag.columnNames[c]));
        tag.columnNames[c]     = JSON.parse(JSON.stringify(tag.columnNames[parseInt(c) + 1]));
        tag.columnNames[parseInt(c) + 1] = JSON.parse(JSON.stringify(tmp));
        break;
      }
    }

    tag.update();
  }

  tag.addAttribute = (e) =>
  {
    var attribute_name = document.getElementById("attributeName").value;
    if(attribute_name.includes("."))
    {
      tag.error_dotInAttribute = true;
      tag.update();
      return;
    }
    else
      tag.error_dotInAttribute = false;

    tag.update();

    if (e.keyCode == 13)
    {
      if(attribute_name.length == 0)
        return;

      tag.columnNames.push({name: document.getElementById("attributeName").value});
      document.getElementById("attributeName").value = "";
      tag.addingNewAttribute = false;
      tag.update();
    }
  }

  tag.addNewAttribute = () =>
  {
    document.getElementById("attributeName").value = "";
    tag.addingNewAttribute = true;
    tag.update();
  }

  tag.cancelChanges = () =>
  {
    var instance = M.Modal.getInstance(document.getElementById('itemAttributes'));
    instance.close();
  }

  tag.saveChanges = () =>
  {
    var new_columns = {columnNames: []};
    for (
          var c = 0
        ; c < tag.columnNames.length
        ; ++c
        )
    {
      new_columns.columnNames.push(tag.columnNames[c].name);
    }
    app.editCategory(tag.checkedCategory.id, new_columns);

    var instance = M.Modal.getInstance(document.getElementById('itemAttributes'));
    instance.close();
  }
  </script>
</item-attributes-modal>
