import riot from "riot"
import app from '../app/_app.js';

export default class Items
{
  constructor()
  {
    riot.observable(this);

    this.items = [];
    this.itemHistory = [];

    this.signals = {
      success: {
        onAddItem        : "items_success_itemAdded"
      , itemEdited       : "items_success_itemEdited"       //trigger sends the edited item
      , itemListLoaded   : "items_success_itemListLoaded"
      , itemHistoryLoaded: "items_success_itemHistoryLoaded"
      , onRemoveItem     : "items_success_itemRemoved"
      }
    , error: {
        onAddItem    : "items_error_onAddItem"
      , onEditItem   : "items_error_onEditItem"
      , onLoadItems  : "items_error_onLoadItems"
      , onLoadHistory: "items_error_onLoadHistory"
      , onRemoveItem : "items_error_onRemoveItem"
      }
    , actions: {
        loading       : "items_actions_loading"
      , loaded        : "items_actions_loaded"
      , loadingHistory: "items_actions_loadingHistory" // additional parameter: item id
      , loadedHistory : "items_actions_loadedHistory"
      , approveDelete : "items_actions_approveDelete"
      }
    }
  }

  loadItems()
  {
    return new Promise((resolve, reject) => {
      this.trigger(this.signals.actions.loading);
      const url = app.resources.cloudFunctionsURL() + "/items";

      var headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', "Bearer " + firebase.auth().currentUser.qa);

      const request = new Request(url, {
        method: 'GET',
        mode: 'cors',
        headers: headers
      });

      fetch(request)
      .then((itemsResponse) =>
      {
        this.items = [];
        return itemsResponse.json();
      })
      .then((response) =>
      {
        this.items = response;
        this.items.sort(function(a, b)
        {
          if(a.name < b.name) return -1;
          if(a.name > b.name) return 1;
          return 0;
        });
        this.trigger(this.signals.success.itemListLoaded);
        this.trigger(this.signals.actions.loaded);
        resolve();
      })
      .catch((error) =>
      {
        console.log("Error getting documents: ", error);
        this.trigger(this.signals.error.onLoadItems);
        this.trigger(this.signals.actions.loaded);
        reject();
      });
    });
  }

  addItem(newItem)
  {
    if(Object.keys(newItem).length === 0 && newItem.constructor === Object)
    {
      this.trigger(this.signals.error.onAddItem);
    }
    this.trigger(this.signals.actions.loading);
    const url = app.resources.cloudFunctionsURL() + "/items";

    var headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Authorization', "Bearer " + firebase.auth().currentUser.qa);

    const request = new Request(url, {
      method: 'POST',
      body: JSON.stringify(newItem),
      mode: 'cors',
      headers: headers
    });

    fetch(request)
    .then((doc) =>
    {
      this.loadItems();
      var id = doc.id;
      newItem.id = id;
      //this.archiveItemState(id, newItem);
      this.trigger(this.signals.success.onAddItem);
      this.trigger(this.signals.actions.loaded);
    })
    .catch((error) =>
    {
      console.log("error addign document:", error)
      this.trigger(this.signals.error.onAddItem);
      this.trigger(this.signals.actions.loaded);
    });
  }

  editItem(itemID, newItemData)
  {
    return new Promise((resolve, reject) => {
      this.trigger(this.signals.actions.loading);
      firebase.firestore().collection("items").doc(itemID).update(newItemData)
      .then((doc) =>
      {
        for(let i in this.items)
        {
          if(this.items[i].id == itemID)
          {
            this.items[i] = newItemData;
          }
        }
        this.trigger(this.signals.success.itemEdited, newItemData);
        this.trigger(this.signals.actions.loaded);
        resolve();
      })
      .catch((error) =>
      {
        console.log(error);
        this.trigger(this.signals.error.onEditItem);
        this.trigger(this.signals.actions.loaded);
        reject();
      });
    });
  }

  removeItem(itemID)
  {
    this.trigger(this.signals.actions.loading);
    firebase.firestore().collection("items").doc(itemID)
    .delete()
    .then(() =>
    {
      this.trigger(this.signals.success.onRemoveItem);
      this.loadItems();
    })
    .catch(function(error)
    {
      this.trigger(this.signals.error.onRemoveItem);
      this.trigger(this.signals.actions.loaded);
      console.error("Error removing document: ", error);
    });
  }

  loadItemHistory(itemID)
  {
    return new Promise((resolve, reject) => {
      this.trigger(this.signals.actions.loadingHistory, itemID);
      firebase.firestore().collection("items").doc(itemID).collection("history")
      .get()
      .then((querySnapshot) =>
      {
        this.itemHistory = [];
        querySnapshot.forEach((doc) =>
        {
          this.itemHistory.push(doc.data());
          this.itemHistory[this.itemHistory.length - 1].id = doc.id;
        });
        this.itemHistory.sort(function(a, b)
        {
          if(a["Date from"] < b["Date from"]) return 1;
          if(a["Date from"] > b["Date from"]) return -1;
          return 0;
        });

        this.trigger(this.signals.success.itemHistoryLoaded);
        this.trigger(this.signals.actions.loadedHistory);
        resolve();
      })
      .catch((error) =>
      {
        console.log("Error getting history: ", error);
        this.trigger(this.signals.error.onLoadHistory);
        this.trigger(this.signals.actions.loadedHistory);
        reject();
      });
    });
  }

  archiveItemState(itemID, oldItemData)
  {
    return new Promise((resolve, reject) => {
      this.trigger(this.signals.actions.loading);
      firebase.firestore().collection("items").doc(itemID).collection("history")
      .add(oldItemData)
      .then(() =>
      {
        this.trigger(this.signals.actions.loaded);
        resolve();
      })
      .catch((error) =>
      {
        console.log("error archiving object", error);
        reject();
      });
    });
  }

  editItemHistory(itemID, historyID, newHistoryData)
  {
    this.trigger(this.signals.actions.loading);
    firebase.firestore().collection("items").doc(itemID).collection("history").doc(historyID)
    .update(newHistoryData)
    .then(() =>
    {
      this.trigger(this.signals.actions.loaded);
    })
    .catch((error) =>
    {
      console.log("error archiving object", error);
    });
  }
}
