import riot from "riot"

export default class Locations
{
  constructor()
  {
    riot.observable(this);

    this.locations = [];

    this.signals = {
      success: {
        onAddLocation        : "locations_success_locationAdded"
      , locationEdited       : "locations_success_locationEdited"       //trigger sends the edited location
      , locationListLoaded   : "locations_success_locationListLoaded"
      , onRemoveLocation     : "locations_success_locationRemoved"
      }
    , error: {
        onAddLocation    : "locations_error_onAddLocation"
      , onEditLocation   : "locations_error_onEditLocation"
      , onLoadLocations  : "locations_error_onLoadLocations"
      , onRemoveLocation : "locations_error_onRemoveLocation"
      }
    , actions: {
        loading       : "locations_actions_loading"
      , loaded        : "locations_actions_loaded"
      , approveDelete : "locations_actions_approveDelete"
      }
    }
  }

  loadLocations()
  {
    return new Promise((resolve, reject) => {
      this.trigger(this.signals.actions.loading);
      firebase.firestore().collection("locations")
      .get()
      .then((querySnapshot) =>
      {
        this.locations = [];
        querySnapshot.forEach((doc) =>
        {
          this.locations.push(doc.data());
          this.locations[this.locations.length - 1].id = doc.id;
        });
        this.locations.sort(function(a, b)
        {
          if(a.name < b.name) return -1;
          if(a.name > b.name) return 1;
          return 0;
        });
        this.trigger(this.signals.success.locationListLoaded);
        this.trigger(this.signals.actions.loaded);
        resolve();
      })
      .catch((error) =>
      {
        console.log("Error getting documents: ", error);
        this.trigger(this.signals.error.onLoadLocations);
        this.trigger(this.signals.actions.loaded);
        reject();
      });
    });
  }

  addLocation(newLocation)
  {
    if(Object.keys(newLocation).length === 0 && newLocation.constructor === Object)
    {
      this.trigger(this.signals.error.onAddLocation);
    }
    this.trigger(this.signals.actions.loading);
    firebase.firestore().collection("locations")
    .add(newLocation)
    .then((doc) =>
    {
      this.loadLocations();
      var id = doc.id;
      newLocation.id = id;
      this.trigger(this.signals.success.onAddLocation);
      this.trigger(this.signals.actions.loaded);
    })
    .catch((error) =>
    {
      console.log("error addign document:", error)
      this.trigger(this.signals.error.onAddLocation);
      this.trigger(this.signals.actions.loaded);
    });
  }

  editLocation(locationID, newLocationData)
  {
    this.trigger(this.signals.actions.loading);
    firebase.firestore().collection("locations").doc(locationID).update(newLocationData)
    .then((doc) =>
    {
      this.trigger(this.signals.success.locationEdited, doc.data());
      this.trigger(this.signals.actions.loaded);
    })
    .catch((error) =>
    {
      this.trigger(this.signals.error.onEditLocation);
      this.trigger(this.signals.actions.loaded);
    });
  }

  removeLocation(locationID)
  {
    return new Promise((resolve, reject) => {
      this.trigger(this.signals.actions.loading);
      firebase.firestore().collection("items").where("locationID", "==", locationID)
      .get()
      .then((querySnapshot) =>
      {
        if(querySnapshot.size > 0)
        {
          // TODO: handle this signal
          this.trigger(this.signals.error.onRemoveLocation);
          this.trigger(this.signals.actions.loaded);
          return;
        }

        firebase.firestore().collection("locations").doc(locationID)
        .delete()
        .then(() =>
        {
          // TODO: handle this signal
          this.trigger(this.signals.success.onRemoveLocation);
          this.loadLocations();
          resolve();
        })
      })
      .catch(function(error)
      {
        // TODO: handle this signal
        this.trigger(this.signals.error.onRemoveLocation);
        this.trigger(this.signals.actions.loaded);
        console.error("Error removing document: ", error);
        reject();
      });
    });
  }
}
