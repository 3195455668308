import riot from "riot"

export default class User
{
  constructor()
  {
    riot.observable(this);

    this.signals = {
      success: {}
    , error: {
        wrongPassword  : "user_error_onLogin_wrongPassword"
      , tooManyRequests: "user_error_onLogin_tooManyRequests"
      , userDisabled   : "user_error_onLogin_userDisabled"
      , onLogout       : "user_error_onLogout"
      }
    }
  }

  login(email, password)
  {
    firebase.auth().signInWithEmailAndPassword(email, password)
    .then(() =>
    {
      window.location.href = '/app/';
    })
    .catch((error) =>
    {
      if(error.code == "auth/wrong-password")
      {
        this.trigger(this.signals.error.wrongPassword);
      }
      else if(error.code == "auth/too-many-requests")
      {
        this.trigger(this.signals.error.tooManyRequests);
      }
      else if(error.code = "auth/user-disabled")
      {
        this.trigger(this.signals.error.userDisabled);
      }
      console.log(error);
    });
  }

  logout()
  {
    firebase.auth().signOut()
    .then(() =>
    {
      // Sign-out successful.
    })
    .catch((error) =>
    {
      this.trigger(this.signals.error.onLogout);
    });
  }
}
