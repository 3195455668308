<locations>
  <div class="col s12 m9 l9 xl10 fill-parent-height">
    <div class="main-text">
      <div class="title">
        Locations
        <i class="material-icons right titleicon-add tooltipped" data-position="bottom" data-tooltip="Add location" onclick = {addNewLocation} show = {checkedCompaniesCount == 1}>add</i>
        <i class="material-icons right titleicon tooltipped" data-position="bottom" data-tooltip="Remove location" show = {checkedCompaniesCount && selectedLocation != undefined} onclick = {askForLocationRemove}>delete</i>
        <i class="material-icons right titleicon tooltipped" data-position="bottom" data-tooltip="Download CSV" show = {checkedCompaniesCount} onclick = {downloadCSV}>get_app</i>
      </div>
    </div>
    <div class="background-locations">
      <div class = "preloader-container">
        <div class="preloader-progress progress preloader-background-red" show = {loadingLocations}>
          <div class="indeterminate preloader-red"></div>
        </div>
      </div>
      <div id="myGrid" class="ag-theme-material {loading: loadingLocations}"></div>
    </div>
  </div>

  <location-approve-delete></location-approve-delete>

  <script>
    import app from 'obs/app/_app.js';
    var tag = this;

    tag.grid = undefined;
    tag.editedRow = undefined;
    tag.checkedCompany = undefined;
    tag.checkedCompaniesCount = 0;
    tag.loadingLocations = false;

    tag.selectedLocation = undefined;

    tag.columnDefs = [
      {headerName: "1 Company", field: "1 Company"}
    , {headerName: "2 ID", field: "2 ID"}
    , {headerName: "3 City", field: "3 City"}
    , {headerName: "4 ZIP code", field: "4 ZIP code"}
    , {headerName: "5 Street, house no", field: "5 Street, house no"}
    , {headerName: "6 E-mail", field: "6 E-mail"}
    , {headerName: "7 Phone", field: "7 Phone"}
    ];

    tag.rowData = [];

    tag.gridOptions = {
      columnDefs: tag.columnDefs,
      rowData: tag.rowData,
      suppressFieldDotNotation: true,
      enableSorting: true,
      enableColResize: true,
      rowSelection: 'single',
      enableFilter: true,
      editType: 'fullRow',
      defaultColDef: {
        editable: true,
        enableValue: true,
        enableRowGroup: true
      },
      onRowValueChanged: function(params)
      {
        tag.editedRow = params.data;
        params.api.updateRowData({update: tag.rowEdited});

        tag.onRowEdited();
      },
      onRowSelected: function(params)
      {
        // check if row is selected because this event is fired twice: for selection and unselection
        if(params.node.selected)
        {
          if(params.data.id != undefined)
          {
            for(let l in app.locations.locations)
            {
              if(app.locations.locations[l].id == params.data.id)
              {
                tag.selectedLocation = app.locations.locations[l];
                tag.update();
              }
            }
          }
        }
      },
      onGridReady: function (params) {
        params.api.sizeColumnsToFit();

        window.addEventListener('resize', function() {
          setTimeout(function() {
            params.api.sizeColumnsToFit();
          })
        })
      }
    };

    tag.on("mount", function()
    {
      var eGridDiv = document.querySelector('#myGrid');
      tag.grid = new agGrid.Grid(eGridDiv, tag.gridOptions);
      tag.gridOptions.api.sizeColumnsToFit();

      var location_attributes = document.getElementById('locationAttributes');
      var instance_attributes = M.Modal.init(location_attributes);

      app.companies.on("companies_actions_onLoadCompaniesData", tag.loadLocationsFromCompanies);
      app.locations.on("locations_success_locationListLoaded" , tag.loadLocationsFromCompanies);
      app.locations.on("locations_actions_loading"            , tag.loading);
      app.locations.on("locations_actions_loaded"             , tag.loaded);
      app.locations.on("locations_actions_approveDelete"      , tag.removeLocation);

      var item_approve_delete     = document.getElementById('locationApproveDelete');
      var instance_approve_delete = M.Modal.init(item_approve_delete);
      var tooltips                = document.querySelectorAll('.tooltipped');
      var tooltip_instances       = M.Tooltip.init(tooltips, {outDuration: 0});
    });

    tag.on("unmount", function()
    {
      app.companies.off("companies_actions_onLoadCompaniesData", tag.loadLocationsFromCompanies);
      app.locations.off("locations_success_locationListLoaded" , tag.loadLocationsFromCompanies);
      app.locations.off("locations_actions_loading"            , tag.loading);
      app.locations.off("locations_actions_loaded"             , tag.loaded);
      app.locations.off("locations_actions_approveDelete"      , tag.removeLocation);
    });

    tag.loading = () =>
    {
      tag.loadingLocations = true;
      tag.update();
    }

    tag.loaded = () =>
    {
      tag.loadingLocations = false;
      tag.update();
    }

    tag.openLocationAttributesModal = () =>
    {
      var instance = M.Modal.getInstance(document.getElementById('locationAttributes'));
      instance.open();
    }

    tag.downloadCSV = () =>
    {
      tag.gridOptions.api.exportDataAsCsv();
    }

    tag.addNewLocation = () =>
    {
      tag.rowData.unshift({companyID: tag.checkedCompany.id, "1 Company": tag.checkedCompany.name});
      tag.gridOptions.api.setRowData(tag.rowData);
    }

    tag.askForLocationRemove = () =>
    {
      var instance = M.Modal.getInstance(document.getElementById('locationApproveDelete'));
      instance.open();
    }

    tag.removeLocation = () =>
    {
      if(tag.selectedLocation != undefined)
        app.removeLocation(tag.selectedLocation.id);
    }

    tag.onRowEdited = () =>
    {
      var location = JSON.parse(JSON.stringify(tag.editedRow));
      delete location["id"];

      if(tag.editedRow.id == undefined)
        app.addLocation(location);
      else
        app.editLocation(tag.editedRow.id, location);

      tag.editedRow = undefined;
    }

    tag.loadLocationsFromCompanies = () =>
    {
      tag.selectedLocation = undefined;

      tag.rowData = [];

      tag.checkedCompaniesCount = 0;
      tag.checkedCompany = undefined;

      // check number of companies that the user has selected + generate column names
      for (
            var c = 0
          ; c < app.companies.companies.length
          ; ++c
          )
      {
        if(app.companies.companies[c].checked)
        {
          tag.checkedCompaniesCount++;
          tag.checkedCompany = app.companies.companies[c];
        }
      }

      // hide the add button
      if(tag.checkedCompaniesCount > 1)
        tag.checkedCompany = undefined;

      for (
            var i = 0
          ; i < app.locations.locations.length
          ; ++i
          )
      {
        for (
              var c = 0
            ; c < app.companies.companies.length
            ; ++c
            )
        {
          if(
               app.companies.companies[c].id == app.locations.locations[i].companyID
            && app.companies.companies[c].checked
            )
          {
            app.locations.locations[i].Company = app.companies.companies[c].name;
            tag.rowData.push(app.locations.locations[i]);
          }
        }
      }

      tag.gridOptions.api.setRowData(tag.rowData);
      tag.update();
    }
  </script>
</locations>
