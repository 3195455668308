import riot from "riot"

export default class Resources
{
  constructor()
  {
    riot.observable(this);

    this.CLOUD_FUNCTIONS_URL = "https://us-central1-lab4motion-inventory.cloudfunctions.net/api";
    //this.CLOUD_FUNCTIONS_URL = "https://api.inventory.lab4motion.com/api";
  }

  cloudFunctionsURL()
  {
    return this.CLOUD_FUNCTIONS_URL;
  }
}
