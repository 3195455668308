<companies>
  <div class="col s12 m3 l3 xl2 fill-parent-height">
    <div class="main-text">
      <div class="title">Companies
        <i class="material-icons right titleicon-add tooltipped" data-position="bottom" data-tooltip="Add company" onclick = {onAddNewCompanyClicked} show = {!loadingCompanies}>add</i>
        <i class="material-icons right titleicon tooltipped" data-position="bottom" data-tooltip="Remove company" onclick = {onDeleteEmptyCompany} show = {!loadingCompanies && selectedCompaniesCount == 1 && locationsInSelectedCompany == 0}>delete</i>
      </div>
    </div>
    <div class="background-companies">
      <div class = "preloader-container">
        <div class="preloader-progress progress preloader-background-white" show = {loadingCompanies}>
          <div class="indeterminate preloader-red"></div>
        </div>
      </div>
      <div class="background">
        <ul>
          <li class = "companies-element" show = {addingNewCompany}>
            <input placeholder = "Company name" id="companyName" type="text" class="validate">
          </li>
          <li onclick = {onCompanyClicked} class = "companies-element" each = {company in companies.companies}>
            <label>
              <input id = "{company.id}_checkbox" type="checkbox" class="filled-in checkbox-white"/>
              <span class="text truncate company-name">{company.name}</span>
              <div class="right square valign-wrapper">
                <a class="center-align square-text">{company.locationsCount}</a>
              </div>
            </label>
          </li>
        </ul>
      </div>
    </div>
  </div>



  <script>
  import app from 'obs/app/_app.js';
    var tag = this;

    tag.addingNewCompany           = false;
    tag.loadingCompanies           = false;
    tag.allCompaniesCount          = 0;
    tag.selectedCompaniesCount     = 0;
    tag.locationsInSelectedCompany = undefined;

    tag.companies = app.companies;

    tag.on("before-mount", function()
    {
      app.companies.on("companies_actions_loading"        , tag.loading);
      app.companies.on("companies_actions_loaded"         , tag.loaded);
      app.companies.on("companies_success_onAddCompany"   , tag.companyAdded);
      app.companies.on("companies_error_onAddCompany"     , tag.companyNotAdded);
      app.companies.on("companies_success_onRemoveCompany", tag.companyRemoved);
    })

    tag.on("mount", function()
    {
      app.on("companies_success_onUpdateCompanyLocationsCount", tag.receivedCompaniesList);
      document.getElementById("companyName").addEventListener('keydown', tag.addCompany);
      var tooltips          = document.querySelectorAll('.tooltipped');
      var tooltip_instances = M.Tooltip.init(tooltips, {outDuration: 0});
    });

    tag.on("before-unmount", function()
    {
      document.getElementById("companyName").removeEventListener('keydown', tag.addCompany);
    })

    tag.on("unmount", function()
    {
      app.off("companies_success_onUpdateCompanyLocationsCount", tag.receivedCompaniesList);
      app.companies.off("companies_actions_loading"        , tag.loading);
      app.companies.off("companies_actions_loaded"         , tag.loaded);
      app.companies.off("companies_success_onAddCompany"   , tag.companyAdded);
      app.companies.off("companies_error_onAddCompany"     , tag.companyNotAdded);
      app.companies.off("companies_success_onRemoveCompany", tag.companyRemoved);
    });

    tag.loading = () =>
    {
      tag.loadingCompanies = true;
      tag.update();
    }

    tag.loaded = () =>
    {
      tag.loadingCompanies = false;
      tag.update();
    }

    tag.companyAdded = () =>
    {
      tag.selectedCompaniesCount = 0;
      tag.addingNewCompany = false;
      tag.update();
    }

    tag.companyNotAdded = () =>
    {
      tag.selectedCompaniesCount = 0;
      tag.addingNewCompany = false;
      tag.update();
    }

    tag.receivedCompaniesList = () =>
    {
      tag.loadingCompanies = false;
      tag.allCompaniesCount = 0;
      for (
            var c = 0
          ; c < tag.companies.companies.length
          ; c++
          )
      {
        tag.allCompaniesCount += tag.companies.companies[c].locationsCount;
      }
      tag.update();
    }

    tag.onAddNewCompanyClicked = () =>
    {
      tag.addingNewCompany = !tag.addingNewCompany;
    }

    tag.addCompany = (e) =>
    {
      if (e.keyCode == 13 || e.type == "click")
      {
        var company_name = document.getElementById("companyName").value;
        if(company_name.length == 0)
          return;

        document.getElementById("companyName").value = "";
        app.addCompany(company_name);
      }
    }

    tag.onCompanyClicked = (e) =>
    {
      if(e.target.tagName != "INPUT")
        return;
      e.item.company.checked = document.getElementById(e.item.company.id + "_checkbox").checked;

      tag.selectedCompaniesCount = 0;
      tag.locationsInSelectedCompany = 0;

      for(var c in app.companies.companies)
      {
        if(app.companies.companies[c].checked)
        {
          tag.selectedCompaniesCount++;
          tag.locationsInSelectedCompany += app.companies.companies[c].locationsCount;
        }
      }

      if(tag.selectedCompaniesCount > 1)
        tag.locationsInSelectedCompany = undefined;

      app.loadCompaniesData();
    }

    tag.onDeleteEmptyCompany = (e) =>
    {
      for(var c in app.companies.companies)
      {
        if(app.companies.companies[c].checked)
        {
          app.removeCompany(app.companies.companies[c].id);
          break;
        }
      }
    }

    tag.companyRemoved = () =>
    {
      tag.selectedCompaniesCount     = 0;
      tag.locationsInSelectedCompany = undefined;
      tag.update();
    }
  </script>
</companies>
