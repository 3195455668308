<content-inventory>
  <div class="row fill-parent-height-64">
    <categories></categories>
    <items></items>
    <item-details></item-details>
  </div>

  <script>
    import app from 'obs/app/_app.js';
    var tag = this;

    tag.on("mount", function()
    {
      tag.loadInventoryData();
    });

    tag.on("unmount", function()
    {

    });

    tag.loadInventoryData = () =>
    {
      app.loadInventoryData();
    }
  </script>
</content-inventory>
