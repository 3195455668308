<item-approve-delete>
  <div id="itemApproveDelete" class="modal">
    <div class="modal-content">
      <div class = "center">
        Are you sure that you want to delete this item with it's history?
        <br>This operation is irreversible!
      </div>
    </div>
    <div class="modal-footer">
      <a class="waves-effect waves-light btn-flat item-button" onclick = {approveDelete}>Yes</a>
      <a class="waves-effect waves-light btn-flat item-button" onclick = {declineDelete}>No</a>
    </div>
  </div>

  <script>
  import app from 'obs/app/_app.js';

  var tag = this;

  tag.on("mount", function()
  {

  });

  tag.on("unmount", function()
  {

  });

  tag.approveDelete = () =>
  {
    app.approveItemDelete();
    var instance = M.Modal.getInstance(document.getElementById('itemApproveDelete'));
    instance.close();
  }

  tag.declineDelete = () =>
  {
    var instance = M.Modal.getInstance(document.getElementById('itemApproveDelete'));
    instance.close();
  }
  </script>
</item-approve-delete>
