<items>
  <div class="col s12 m5 l6 xl7 fill-parent-height">
    <div class="main-text">
      <div class="title">
        Items
        <i class="material-icons right titleicon-add tooltipped" data-position="bottom" data-tooltip="Add item" onclick = {addNewItem}>add</i>
        <i class="material-icons right titleicon modal-trigger tooltipped" data-position="bottom" data-tooltip="Edit columns" data-target="itemAttributes" show = {checkedCategoriesCount == 1} onclick = {openItemAttributesModal}>view_column</i>
        <i class="material-icons right titleicon tooltipped" data-position="bottom" data-tooltip="Download CSV" show = {checkedCategoriesCount} onclick = {downloadCSV}>get_app</i></div>
    </div>
    <div class="background-items">
      <div class = "preloader-container">
        <div class="preloader-progress progress preloader-background-red" show = {loadingItems}>
          <div class="indeterminate preloader-red"></div>
        </div>
      </div>
      <div id="myGrid" class="ag-theme-material {loading: loadingItems}"></div>
    </div>
  </div>

  <item-attributes-modal></item-attributes-modal>
  <items-add-item-modal></items-add-item-modal>
  <items-edit-item-modal></items-edit-item-modal>

  <script>
    import app from 'obs/app/_app.js';
    var tag = this;

    tag.grid                   = undefined;
    tag.editedRow              = undefined;
    tag.checkedCategory        = undefined;
    tag.checkedCategoriesCount = 0;
    tag.loadingItems           = false;
    tag.selectedItem           = undefined;

    tag.columnDefs = [];

    tag.rowData = [];

    tag.gridOptions = {
      columnDefs: tag.columnDefs,
      rowData: tag.rowData,
      suppressFieldDotNotation: true,
      enableSorting: true,
      enableColResize: true,
      rowSelection: 'single',
      enableFilter: true,
      defaultColDef: {
        editable: false,
        enableValue: true,
        enableRowGroup: true
      },
      onRowSelected: function(params)
      {
        // check if row is selected because this event is fired twice: for selection and unselection
        if(params.node.selected)
        {
          if(params.data.id != undefined)
          {
            for(let i in app.items.items)
            {
              if(app.items.items[i].id == params.data.id)
                tag.selectedItem = app.items.items[i];
            }
            app.loadItemHistory(params.data.id);
          }
          else
          {
            app.clearHistoryView();
          }
        }
      },
      onGridReady: function (params) {

      }
    };

    tag.on("mount", function()
    {
      var eGridDiv = document.querySelector('#myGrid');
      tag.grid = new agGrid.Grid(eGridDiv, tag.gridOptions);
      tag.gridOptions.api.sizeColumnsToFit();

      var item_attributes       = document.getElementById('itemAttributes');
      var instance_attributes   = M.Modal.init(item_attributes);
      var items_add_item_modal  = document.getElementById('itemsAddItem');
      var instance_add_item     = M.Modal.init(items_add_item_modal, {onOpenEnd: tag.sendDataToAddItem});
      var items_edit_item_modal = document.getElementById('itemsEditItem');
      var instance_edit_item    = M.Modal.init(items_edit_item_modal, {onOpenEnd: tag.sendDataToEditItem});
      var tooltips              = document.querySelectorAll('.tooltipped');
      var tooltip_instances     = M.Tooltip.init(tooltips, {outDuration: 0});

      app.categories.on("categories_actions_onLoadCategoriesData", tag.loadItemsFromCategories);
      app.items.on("items_success_itemListLoaded"                , tag.loadItemsFromCategories);
      app.items.on("items_success_itemEdited"                    , tag.itemEdited);
      app.items.on("items_actions_loading"                       , tag.loading);
      app.items.on("items_actions_loaded"                        , tag.loaded);
    });

    tag.on("unmount", function()
    {
      app.categories.off("categories_actions_onLoadCategoriesData", tag.loadItemsFromCategories);
      app.items.off("items_success_itemListLoaded"                , tag.loadItemsFromCategories);
      app.items.off("items_success_itemEdited"                    , tag.itemEdited);
      app.items.off("items_actions_loading"                       , tag.loading);
      app.items.off("items_actions_loaded"                        , tag.loaded);
    });

    tag.loading = () =>
    {
      tag.loadingItems = true;
      tag.update();
    }

    tag.loaded = () =>
    {
      tag.loadingItems = false;
      tag.update();
    }

    tag.openItemAttributesModal = () =>
    {
      var instance = M.Modal.getInstance(document.getElementById('itemAttributes'));
      instance.open();
    }

    tag.downloadCSV = () =>
    {
      tag.gridOptions.api.exportDataAsCsv();
    }

    tag.addNewItem = () =>
    {
      var instance = M.Modal.getInstance(document.getElementById('itemsAddItem'));
      instance.open();
      // tag.rowData.unshift({categoryID: tag.checkedCategory.id, Category: tag.checkedCategory.name});
      // tag.gridOptions.api.setRowData(tag.rowData);
    }

    tag.sendDataToAddItem = () =>
    {
      app.sendDataToAddItem();
    }

    tag.sendDataToEditItem = () =>
    {
      app.sendDataToEditItem(tag.selectedItem);
    }

    tag.itemEdited = (item) =>
    {
      tag.loadItemsFromCategories();
      let i = 0;
      for(let r in tag.rowData)
      {
        if(tag.rowData[r].id == item.id)
        {
          tag.gridOptions.api.forEachNode(node => node.rowIndex == i ? node.setSelected(true) : 0);
          break;
        }
        i++;
      }
    }

    tag.loadItemsFromCategories = () =>
    {
      tag.rowData = [];

      tag.checkedCategoriesCount = 0;
      tag.checkedCategory = undefined;

      // clear column defnitions efore column names update definition
      tag.columnDefs = [];
      let columns = [];

      // check number of categories that the user has selected + generate column names
      for (
            var c = 0
          ; c < app.categories.categories.length
          ; ++c
          )
      {
        if(app.categories.categories[c].checked)
        {
          tag.checkedCategoriesCount++;
          tag.checkedCategory = app.categories.categories[c];
          for (
                var i = 0
              ; i < app.categories.categories[c].columnNames.length
              ; ++i
              )
          {
            columns.push({headerName: app.categories.categories[c].columnNames[i], field: app.categories.categories[c].columnNames[i]});
          }
        }
      }

      if(tag.checkedCategoriesCount)
      {
        tag.columnDefs.push({headerName: "Category", field: "Category", editable: false})
        // TODO: this should be done much better
        // right now I don't have any information about location columns
        if(app.locations.locations.length)
        {
          for(let l in app.locations.locations[0])
          {
            if(
              !(
                   l == "id"
                || l == "companyID"
              )
              )
            {
              columns.push({headerName: l, field: l, editable: false});
            }
          }
        }
      }

      columns.forEach(function(item)
      {
        let is_in_array = false;
        for(var c in tag.columnDefs)
        {
          if(JSON.stringify(tag.columnDefs[c]) == JSON.stringify(item))
          {
            is_in_array = true;
            break;
          }
        }
        if(!is_in_array)
          tag.columnDefs.push(item);
      });

      // update column names
      tag.gridOptions.api.setColumnDefs(tag.columnDefs);

      // hide the add button
      if(tag.checkedCategoriesCount > 1)
        tag.checkedCategory = undefined;

      for (
            var i = 0
          ; i < app.items.items.length
          ; ++i
          )
      {
        for (
              var c = 0
            ; c < app.categories.categories.length
            ; ++c
            )
        {
          if(
               app.categories.categories[c].id == app.items.items[i].categoryID
            && app.categories.categories[c].checked
            )
          {
            app.items.items[i].Category = app.categories.categories[c].name;
            tag.rowData.push(JSON.parse(JSON.stringify(app.items.items[i])));
            for(let l in app.items.items[i].location)
            {
              if(l != "id")
                tag.rowData[tag.rowData.length - 1][l] = app.items.items[i].location[l];
            }
          }
        }
      }

      tag.gridOptions.api.setRowData(tag.rowData);
      tag.update();
    }
  </script>
</items>
