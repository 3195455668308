import riot from "riot";
import User from '../user/_user.js';
import Categories from '../categories/_categories.js';
import Items from '../items/_items.js';
import Companies from '../companies/_companies.js';
import Locations from '../locations/_locations.js';
import Resources from '../resources/_resources.js';
import moment from 'moment'

class App
{
  constructor()
  {
    riot.observable(this);

    if(!App.instance)
    {
      this._firestore  = firebase.firestore();
      const settings = {timestampsInSnapshots: true};
      this._firestore.settings(settings);

      this.signals = {
        success: {
          onUpdateCategoryItemsCount   : "categories_success_onUpdateCategoryItemsCount"
        , onUpdateCompanyLocationsCount: "companies_success_onUpdateCompanyLocationsCount"
        }
      , error: {
        }
      , actions: {
          clearHistoryView  : "app_actions_clearHistoryView"
        , changeMode        : "app_actions_changeMode"        //additional parameter is being passed here with the mode name
        , sendDataToAddItem : "app_actions_sendDataToAddItem"
        , sendDataToEditItem: "app_actions_sendDataToEditItem"
        }
      }

      this._user       = new User();
      this._categories = new Categories();
      this._items      = new Items();
      this._companies  = new Companies();
      this._locations  = new Locations();
      this._resources  = new Resources();

      App.instance = this;

      this._items.on(App.instance._items.signals.success.itemListLoaded, this.updateCategoryItemsCount);
      this._locations.on(App.instance._locations.signals.success.locationListLoaded, this.updateCompanyLocationsCount);
   }

   return App.instance;
  }

  get firestore()
  {
    return App.instance._firestore;
  }

  get user()
  {
    return App.instance._user;
  }

  get categories()
  {
    return App.instance._categories;
  }

  get items()
  {
    return App.instance._items;
  }

  get companies()
  {
    return App.instance._companies;
  }

  get locations()
  {
    return App.instance._locations;
  }

  get resources()
  {
    return App.instance._resources;
  }

  changeMode(modeName)
  {
    App.instance.trigger(App.instance.signals.actions.changeMode, modeName);
  }

  login(email, password)
  {
    App.instance._user.login(email, password);
  }

  logout()
  {
    App.instance._user.logout();
  }

  async loadInventoryData()
  {
    await App.instance._companies.loadCompanies();
    await App.instance._locations.loadLocations();
    await App.instance._categories.loadCategories();
    await App.instance._items.loadItems();
  }

  async loadCategories()
  {
    await App.instance._categories.loadCategories();
    App.instance.updateCategoryItemsCount();
  }

  addCategory(categoryName)
  {
    App.instance._categories.addCategory(categoryName);
  }

  async removeCategory(categoryID)
  {
    await App.instance._categories.removeCategory(categoryID);
    await App.instance._categories.loadCategories();
    App.instance.updateCategoryItemsCount();
  }

  editCategory(categoryID, newCategoryData)
  {
    App.instance._categories.editCategory(categoryID, newCategoryData);
  }

  updateCategoryItemsCount()
  {
    for (
          var c = 0
        ; c < App.instance._categories.categories.length
        ; ++c
        )
    {
      App.instance._categories.categories[c].itemsCount = 0;
    }
    for (
          var i = 0
        ; i < App.instance._items.items.length
        ; ++i
        )
    {
      for (
            var c = 0
          ; c < App.instance._categories.categories.length
          ; ++c
          )
      {
        if(App.instance._categories.categories[c].id == App.instance._items.items[i].categoryID)
        {
          App.instance._categories.categories[c].itemsCount++;
        }
      }
    }

    App.instance.trigger(App.instance.signals.success.onUpdateCategoryItemsCount);
  }

  loadItems()
  {
    App.instance._items.loadItems();
  }

  loadCategoriesData()
  {
    App.instance._categories.trigger(App.instance._categories.signals.actions.onLoadCategoriesData);
  }

  approveItemDelete()
  {
    App.instance._items.trigger(App.instance._items.signals.actions.approveDelete);
  }

  removeItem(itemID)
  {
    App.instance._items.removeItem(itemID);
  }

  addItem(newItem)
  {
    newItem["Date from"] = moment().format('YYYY.MM.DD, HH:mm:ss');
    App.instance._items.addItem(newItem);
  }

  sendDataToAddItem()
  {
    App.instance.trigger(App.instance.signals.actions.sendDataToAddItem);
  }

  sendDataToEditItem(item)
  {
    App.instance.trigger(App.instance.signals.actions.sendDataToEditItem, item);
  }

  // 1. update the newest history object
  // 2. update currnt object
  // 3. set new object data as the new history state
  async editItem(itemID, newItemData)
  {
    let old_item_data = App.instance._items.itemHistory[0];
    old_item_data["Date to"] = moment().format('YYYY.MM.DD, HH:mm:ss');
    App.instance._items.editItemHistory(itemID, old_item_data.id, old_item_data);

    newItemData["Date from"] = moment().format('YYYY.MM.DD, HH:mm:ss');
    await App.instance._items.archiveItemState(itemID, newItemData);
    await App.instance._items.editItem(itemID, newItemData);
    await App.instance._items.loadItemHistory(itemID);
  }

  loadItemHistory(itemID)
  {
    App.instance._items.loadItemHistory(itemID);
  }

  clearHistoryView()
  {
    App.instance.trigger(App.instance.signals.actions.clearHistoryView);
  }

  /*
   * locations
   */

  async loadLocationsData()
  {
    await App.instance._companies.loadCompanies();
    await App.instance._locations.loadLocations();
  }

  loadLocations()
  {
    App.instance._locations.loadLocations();
  }

  loadCompanies()
  {
    App.instance._companies.loadCompanies();
  }

  async addCompany(companyName)
  {
    await App.instance._companies.addCompany(companyName);
    App.instance.loadLocationsData();
  }

  async removeCompany(companyID)
  {
    await App.instance._companies.removeCompany(companyID);
    App.instance.loadLocationsData();
  }

  loadCompaniesData()
  {
    App.instance._companies.trigger(App.instance._companies.signals.actions.onLoadCompaniesData);
  }

  updateCompanyLocationsCount()
  {
    for (
          var c = 0
        ; c < App.instance._companies.companies.length
        ; ++c
        )
    {
      App.instance._companies.companies[c].locationsCount = 0;
    }
    for (
          var i = 0
        ; i < App.instance._locations.locations.length
        ; ++i
        )
    {
      for (
            var c = 0
          ; c < App.instance._companies.companies.length
          ; ++c
          )
      {
        if(App.instance._companies.companies[c].id == App.instance._locations.locations[i].companyID)
        {
          App.instance._companies.companies[c].locationsCount++;
        }
      }
    }

    App.instance.trigger(App.instance.signals.success.onUpdateCompanyLocationsCount);
  }

  addLocation(newLocation)
  {
    App.instance._locations.addLocation(newLocation);
  }

  editLocation(locationID, newLocationData)
  {
    App.instance._locations.editLocation(locationID, newLocationData);
  }

  approveLocationDelete()
  {
    App.instance._locations.trigger(App.instance._locations.signals.actions.approveDelete);
  }

  removeLocation(locationID)
  {
    App.instance._locations.removeLocation(locationID).catch((e) => {console.log(e)});
  }
}

const app = new App();
Object.freeze(app);

export default app;
