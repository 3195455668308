<items-edit-item-modal>
  <div id="itemsEditItem" class="modal modal-fixed-footer full-screen-modal">
    <div class="modal-content">
      <h6 class = "red-text center" show = {error_categoryMissing}>You must specify a category</h6>
      <h6 class = "red-text center" show = {error_locationMissing}>You must specify a location</h6>
      <div class="input-field col s12">
        <select id = "itemEditCategorySelect">
          <option value = "" disabled selected>Category</option>
          <option each = {category in categories} value = {category.id}>{category.name}</option>
        </select>
      </div>
      <div class="input-field col s12" each = {column, id in itemColumns}>
        <input id="itemsEditItem_{column}" type="text" class="validate">
        <label for="itemsEditItem_{column}">{column}</label>
      </div>
      <div class="input-field col s12">
        <select id = "itemEditCompanySelect">
          <option value = "" disabled selected>Company</option>
          <option each = {company in companies} value = {company.id}>{company.name}</option>
        </select>
      </div>
      <div class="input-field col s12">
        <select id = "itemEditLocationSelect">
          <option value = "" disabled selected>Location</option>
          <option each = {location in companyLocations} value = {location.id}>{location.name}</option>
        </select>
      </div>
    </div>
    <div class="modal-footer">
      <a class="waves-effect waves-light btn-flat item-button" onclick = {cancelChanges}>Cancel</a>
      <a class="waves-effect waves-light btn-flat item-button" onclick = {editItem}>Edit</a>
    </div>
  </div>

  <script>
  import app from 'obs/app/_app.js';
  var tag = this;

  tag.categories       = [];
  tag.companies        = [];
  tag.locations        = [];
  tag.companyLocations = [];
  tag.itemColumns      = [];
  tag.companyColumns   = [];

  tag.categorySelect         = undefined;
  tag.categorySelectInstance = undefined;
  tag.companySelect          = undefined;
  tag.companySelectInstance  = undefined;
  tag.locationSelect         = undefined;
  tag.locationSelectInstance = undefined;

  tag.itemToEdit = undefined;

  tag.error_categoryMissing = false;
  tag.error_locationMissing = false;

  tag.on("before-mount", function()
  {

  });

  tag.on("mount", function()
  {
    tag.categorySelect = document.getElementById('itemEditCategorySelect');
    tag.companySelect  = document.getElementById('itemEditCompanySelect');
    tag.locationSelect = document.getElementById('itemEditLocationSelect');
    tag.categorySelect.addEventListener("change", tag.categoryValueChanged);
    tag.companySelect.addEventListener("change" , tag.companyValueChanged);
    tag.locationSelect.addEventListener("change", tag.locationValueChanged);
    app.on("app_actions_sendDataToEditItem", tag.gatherDataToEditItem);
  });

  tag.on("before-unmount", function()
  {

  });

  tag.on("unmount", function()
  {
    tag.categorySelect.removeEventListener("change", tag.categoryValueChanged);
    tag.companySelect.removeEventListener("change" , tag.companyValueChanged);
    tag.locationSelect.removeEventListener("change", tag.locationValueChanged);
    app.off("app_actions_sendDataToEditItem", tag.gatherDataToEditItem);
  });

  tag.gatherDataToEditItem = (item) =>
  {
    tag.itemToEdit = JSON.parse(JSON.stringify(item));
    tag.categories = app.categories.categories;
    tag.locations  = app.locations.locations;
    tag.companies  = app.companies.companies;
    tag.update();

    // set everything for the user

    // set category
    tag.categorySelectInstance = M.FormSelect.init(tag.categorySelect);

    // set text fields
    tag.itemColumns = [];
    for(let c in tag.categories)
    {
      if(tag.categories[c].id == item.categoryID)
      {
        tag.itemColumns = tag.categories[c].columnNames;
        break;
      }
    }

    tag.update();

    for(let i in tag.itemColumns)
    {
      document.getElementById("itemsEditItem_" + tag.itemColumns[i]).value = item[tag.itemColumns[i]];
    }
    M.updateTextFields();

    let selected_company = undefined;
    // set company and locations
    for(let l in tag.locations)
    {
      if(tag.locations[l].id == item.locationID)
      {
        for(let c in tag.companies)
        {
          if(tag.companies[c].id == tag.locations[l].companyID)
          {
            selected_company = tag.companies[c];
            tag.concatenateLocations(tag.companies[c]);
            break;
          }
        }
      }
      if(selected_company != undefined)
        break;
    }

    tag.update();

    tag.categorySelect.value = item.categoryID;
    tag.companySelect.value  = selected_company.id;
    tag.locationSelect.value = item.locationID;

    tag.categorySelectInstance = M.FormSelect.init(tag.categorySelect);
    tag.companySelectInstance  = M.FormSelect.init(tag.companySelect);
    tag.locationSelectInstance = M.FormSelect.init(tag.locationSelect);
  }

  tag.categoryValueChanged = () =>
  {
    tag.error_categoryMissing = false;

    let values = M.FormSelect.init(tag.categorySelect).getSelectedValues();

    tag.itemColumns = [];
    for(let c in tag.categories)
    {
      if(tag.categories[c].id == values[0])
      {
        tag.itemColumns = tag.categories[c].columnNames;
        break;
      }
    }

    tag.update();
    M.updateTextFields();
  }

  tag.companyValueChanged = () =>
  {
    let values = M.FormSelect.init(tag.companySelect).getSelectedValues();

    for(let c in tag.companies)
    {
      if(tag.companies[c].id == values[0])
      {
        tag.concatenateLocations(tag.companies[c]);
        break;
      }
    }

    tag.update();
    tag.locationSelectInstance = M.FormSelect.init(tag.locationSelect);
  }

  tag.locationValueChanged = () =>
  {
    tag.error_locationMissing = false;
    tag.update();
  }

  tag.concatenateLocations = (company) =>
  {
    tag.companyLocations = [];
    for(let l in tag.locations)
    {
      if(tag.locations[l].companyID == company.id)
      {
        let concatenated_name = "";
        for(let k in tag.locations[l])
        {
          if(
               k == "id"
            || k == "companyID"
            )
            continue;

          concatenated_name += tag.locations[l][k];
          concatenated_name += ", ";
        }
        tag.companyLocations.push({id: tag.locations[l].id, name: concatenated_name});
      }
    }
  }

  tag.cancelChanges = () =>
  {
    var instance = M.Modal.getInstance(document.getElementById('itemsEditItem'));
    instance.close();
  }

  tag.editItem = () =>
  {
    tag.error_categoryMissing = false;
    tag.error_locationMissing = false;

    let category = M.FormSelect.init(tag.categorySelect).getSelectedValues();
    if(!category.length || category[0] == "")
    {
      tag.error_categoryMissing = true;
      tag.update();
      return;
    }

    let location = M.FormSelect.init(tag.locationSelect).getSelectedValues();

    if(!location.length || location[0] == "")
    {
      tag.error_locationMissing = true;
      tag.update();
      return;
    }

    tag.update();

    var edited_item = {
                        categoryID: category[0]
                      , locationID: location[0]
                      };

    for(let l in  tag.locations)
    {
      if(tag.locations[l].id == location[0])
      {
        edited_item.location = tag.locations[l];
        break;
      }
    }

    for(let c in tag.categories)
    {
      if(tag.categories[c].id == category[0])
      {
        for(let col in tag.categories[c].columnNames)
        {
          edited_item[tag.categories[c].columnNames[col]] = document.getElementById("itemsEditItem_" + tag.categories[c].columnNames[col]).value;
        }
      }
    }

    let a = JSON.parse(JSON.stringify(edited_item));
    let b = JSON.parse(JSON.stringify(tag.itemToEdit));
    delete a.id;
    delete b.id;
    delete a["Date from"];
    delete b["Date from"];

    // keys may have different order :/
    const ordered_a = {};
    const ordered_b = {};
    Object.keys(a).sort().forEach(function(key) {
      ordered_a[key] = a[key];
    });
    Object.keys(b).sort().forEach(function(key) {
      ordered_b[key] = b[key];
    });

    if(JSON.stringify(ordered_a) != JSON.stringify(ordered_b))
    {
      edited_item.id = tag.itemToEdit.id;
      app.editItem(edited_item.id, edited_item);

      var instance = M.Modal.getInstance(document.getElementById('itemsEditItem'));
      instance.close();
    }

    // TODO: give information to the user that he cannot edit
  }
  </script>
</items-edit-item-modal>
