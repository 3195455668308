<navbar>
  <nav>
    <div class="nav-wrapper">
      <a class="brand-logo center" id="navbar-title">INVENTORY</a>
      <a class="brand-logo right dropdown-trigger" id="auth" data-target='user-menu'>NK</a>

      <ul class="left">
        <li><a class="dropdown-trigger drop-inv" data-target="dropdown-inventory">{currentMode}<i class="material-icons right">arrow_drop_down</i></a></li>
      </ul>

      <ul id='user-menu' class='dropdown-content'>
        <li><a href class = "pink-text text-darken-3" onclick = {logout}>Logout</a></li>
      </ul>
    </div>
  </nav>

  <ul id="dropdown-inventory" class="dropdown-content drop-list">
    <li><a href="#inventory">Inventory</a></li>
    <li><a href="#locations">Locations</a></li>
  </ul>

  <script>
    import app from 'obs/app/_app.js';
    var tag = this;

    tag.currentMode = "";

    tag.on("before-mount", function()
    {
      app.on("app_actions_changeMode", tag.onChangeMode);
    });

    tag.on("mount", function()
    {
      console.log("---MOUNT: NAVBAR---");
      app.on("app_actions_changeMode", tag.onChangeMode);
      var elems = document.querySelectorAll('.dropdown-trigger');
      var instances = M.Dropdown.init(elems, {coverTrigger: false});
    });

    tag.on("unmount", function()
    {
      app.off("app_actions_changeMode", tag.onChangeMode);
      console.log("---UNMOUNT: NAVBAR---");
    });

    tag.logout = () =>
    {
      app.logout();
    }

    tag.onChangeMode = (currentMode) =>
    {
      tag.currentMode = currentMode;
      tag.update();
    }
  </script>

</navbar>
