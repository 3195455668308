<item-details>

  <div class="col s12 m4 l3 xl3 fill-parent-height">
    <div class="main-text">
      <div class="title">Details</div>
    </div>

    <div class="background-details">
      <div class = "preloader-container">
        
        <div class="preloader-progress progress preloader-background-red" show = {loadingItemHistory}>
          <div class="indeterminate preloader-red"></div>
        </div>
      </div>
      <div class="panda"></div> <!-- panda image -->
      <div class = "background">
        <ul>
          <div class="second-title-actions">Actions</div>

          <li show = {!loadingItemHistory && itemID != undefined} class="actions-element action-element modal-trigger" data-target="itemsEditItem" onclick = {openEditItemModal}>
            Edit<i class="material-icons left">edit</i>
          </li>
          <li show = {!loadingItemHistory && itemID != undefined} class="actions-element action-element modal-trigger" data-target="itemApproveDelete" onclick = {askForItemRemove}>
            Delete<i class="material-icons left">delete</i>
          </li>
        </ul>

        <div class="second-title-history">History</div>
        <ul show = {!loadingItemHistory && itemID != undefined}>
          <li class = {history-element-first: !id, history-element-second: id} each = {item, id in itemHistory}>
            <div each = {obj, key in item} if = {!(key == "id" || key == "categoryID" || key == "locationID" || key == "location")} class = {changed-value: parseInt(id) + 1 < itemHistory.length && key != "Date from" && key != "Date to" && (itemHistory[id][key] != itemHistory[id + 1][key])}>
              {key}: {item[key]}
            </div>
            <br>
            LOCATION
            <div each = {obj, key in item.location} if = {!(key == "id" || key == "companyID")} class = {changed-value: parseInt(id) + 1 < itemHistory.length && key != "Date from" && key != "Date to" && (itemHistory[id][key] != itemHistory[id + 1][key])}>
              {key}: {obj}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <item-approve-delete></item-approve-delete>

  <script>
    import app from 'obs/app/_app.js';
    var tag = this;

    tag.itemID = undefined;
    tag.loadingItemHistory = false;

    tag.itemHistory = app.items.itemHistory;

    tag.on("mount", function()
    {
      app.on("app_actions_clearHistoryView"                      , tag.clearHistoryView);
      app.categories.on("categories_actions_onLoadCategoriesData", tag.clearHistoryView);
      app.items.on("items_success_itemHistoryLoaded"             , tag.receivedItemHistory);
      app.items.on("items_success_itemRemoved"                   , tag.clearHistoryView);
      app.items.on("items_error_onRemoveItem"                    , tag.errorRemovingItem);
      app.items.on("items_actions_loadingHistory"                , tag.loadingHistory);
      app.items.on("items_actions_loadingHistory"                , tag.loading);
      app.items.on("items_actions_loadedHistory"                 , tag.loaded);
      app.items.on("items_actions_approveDelete"                 , tag.removeItem);

      var item_approve_delete     = document.getElementById('itemApproveDelete');
      var instance_approve_delete = M.Modal.init(item_approve_delete);
    });

    tag.on("unmount", function()
    {
      app.off("app_actions_clearHistoryView"                      , tag.clearHistoryView);
      app.categories.off("categories_actions_onLoadCategoriesData", tag.clearHistoryView);
      app.items.off("items_success_itemHistoryLoaded"             , tag.receivedItemHistory);
      app.items.off("items_success_itemRemoved"                   , tag.clearHistoryView);
      app.items.off("items_error_onRemoveItem"                    , tag.errorRemovingItem);
      app.items.off("items_actions_loadingHistory"                , tag.loadingHistory);
      app.items.off("items_actions_loadingHistory"                , tag.loading);
      app.items.off("items_actions_loadedHistory"                 , tag.loaded);
      app.items.off("items_actions_approveDelete"                 , tag.removeItem);
    });

    tag.loading = () =>
    {
      tag.loadingItemHistory = true;
      tag.update();
    }

    tag.loaded = () =>
    {
      tag.loadingItemHistory = false;
      tag.update();
    }

    tag.loadingHistory = (itemID) =>
    {
      tag.itemID = itemID;
    }

    tag.receivedItemHistory = () =>
    {
      tag.itemHistory = app.items.itemHistory;
      tag.loadingItemHistory = false;
      tag.update();
    }

    tag.askForItemRemove = () =>
    {
      var instance = M.Modal.getInstance(document.getElementById('itemApproveDelete'));
      instance.close();
    }

    tag.openEditItemModal = () =>
    {
      var instance = M.Modal.getInstance(document.getElementById('itemsEditItem'));
      instance.open();
    }

    tag.removeItem = () =>
    {
      app.removeItem(tag.itemID);
    }

    tag.errorRemovingItem = () =>
    {
      // todo add information that item could not be deleted
      tag.loaded();
    }

    tag.clearHistoryView = () =>
    {
      tag.itemID = undefined;
      tag.itemHistory = {};

      tag.loaded();
    }
  </script>
</item-details>
