<categories>

  <div class="col s12 m3 l3 xl2 fill-parent-height">
    <div class="main-text">
      <div class="title">Categories
        <i class="material-icons right titleicon-add tooltipped" data-position="bottom" data-tooltip="Add category" onclick = {onAddNewCategoryClicked} show = {!loadingCategories}>add</i>
        <i class="material-icons right titleicon tooltipped" data-position="bottom" data-tooltip="Remove category" onclick = {onDeleteEmptyCategory} show = {!loadingCategories && selectedCategoriesCount == 1 && itemsInSelectedCategory == 0}>delete</i>
      </div>
    </div>
    <div class="background-categories">
      <div class = "preloader-container">
        <div class="preloader-progress progress preloader-background-white" show = {loadingCategories}>
          <div class="indeterminate preloader-red"></div>
        </div>
      </div>
      <div class="background">
        <ul>
          <li class = "categories-element" show = {addingNewCategory}>
            <input placeholder = "Category name" id="categoryName" type="text" class="validate">
          </li>
          <li onclick = {onCategoryClicked} class = "categories-element" each = {category in categories.categories}>
            <label>
              <input id = "{category.id}_checkbox" type="checkbox" class="filled-in checkbox-white"/>
              <span class="text truncate category-name">{category.name}</span>
              <div class="right square valign-wrapper">
                <a class="center-align square-text">{category.itemsCount}</a>
              </div>
            </label>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <script>
    import app from 'obs/app/_app.js';
    var tag = this;

    tag.addingNewCategory       = false;
    tag.loadingCategories       = false;
    tag.allCategoriesCount      = 0;
    tag.selectedCategoriesCount = 0;
    tag.itemsInSelectedCategory = undefined;

    tag.categories = app.categories;

    tag.on("before-mount", function()
    {
      app.on("categories_success_onUpdateCategoryItemsCount", tag.receivedCategoriesList);
      app.categories.on("categories_success_onAddCategory"  , tag.categoryAdded);
      app.categories.on("categories_actions_loading"        , tag.loading);
      app.categories.on("categories_actions_loaded"         , tag.loaded);
      app.locations.on("locations_actions_loading"          , tag.loading);
      app.locations.on("locations_actions_loaded"           , tag.loaded);
    });

    tag.on("mount", function()
    {
      document.getElementById("categoryName").addEventListener('keydown', tag.addCategory);
      var tooltips          = document.querySelectorAll('.tooltipped');
      var tooltip_instances = M.Tooltip.init(tooltips, {outDuration: 0});
    });

    tag.on("before-unmount", function()
    {
      document.getElementById("categoryName").removeEventListener('keydown', tag.addCategory);
    })

    tag.on("unmount", function()
    {
      app.off("categories_success_onUpdateCategoryItemsCount", tag.receivedCategoriesList);
      app.categories.off("categories_success_onAddCategory"  , tag.categoryAdded);
      app.categories.off("categories_actions_loading"        , tag.loading);
      app.categories.off("categories_actions_loaded"         , tag.loaded);
      app.locations.off("locations_actions_loading"          , tag.loading);
      app.locations.off("locations_actions_loaded"           , tag.loaded);
    });

    tag.loading = () =>
    {
      tag.loadingCategories = true;
      tag.update();
    }

    tag.loaded = () =>
    {
      tag.loadingCategories = false;
      tag.update();
    }

    tag.receivedCategoriesList = () =>
    {
      tag.loadingCategories = false;
      tag.allCategoriesCount = 0;
      for (
            var c = 0
          ; c < tag.categories.categories.length
          ; c++
          )
      {
        tag.allCategoriesCount += tag.categories.categories[c].itemsCount;
      }
      tag.update();
    }

    tag.onAddNewCategoryClicked = () =>
    {
      tag.addingNewCategory = !tag.addingNewCategory;
    }

    tag.addCategory = (e) =>
    {
      if (e.keyCode == 13 || e.type == "click")
      {
        var category_name = document.getElementById("categoryName").value;
        if(category_name.length == 0)
          return;

        document.getElementById("categoryName").value = "";

        app.addCategory(category_name);
      }
    }

    tag.categoryAdded = () =>
    {
      app.loadCategories();
    }

    tag.onCategoryClicked = (e) =>
    {
      if(e.target.tagName != "INPUT")
        return;
      e.item.category.checked = document.getElementById(e.item.category.id + "_checkbox").checked;

      tag.selectedCategoriesCount = 0;
      tag.itemsInSelectedCategory = 0;

      for(var c in app.categories.categories)
      {
        if(app.categories.categories[c].checked)
        {
          tag.selectedCategoriesCount++;
          tag.itemsInSelectedCategory += app.categories.categories[c].itemsCount;
        }
      }

      if(tag.selectedCategoriesCount > 1)
        tag.itemsInSelectedCategory = undefined;

      app.loadCategoriesData();
    }

    tag.onDeleteEmptyCategory = (e) =>
    {
      for(var c in app.categories.categories)
      {
        if(app.categories.categories[c].checked)
        {
          app.removeCategory(app.categories.categories[c].id);
          break;
        }
      }
    }
  </script>


</categories>
